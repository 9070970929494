// i18next-extract-mark-ns-start adyen-alternative

import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {IndexBackground} from 'components/landings/IndexBackground';
import {List} from 'components/List';
import {MobilePlanLabel, PlansContainer, PricingSection} from 'components/pricing/PricingSection';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import logo from 'images/adyen.svg';
import selling from 'images/selling.svg';
import React from 'react';
import {HiCheck, HiOutlineX} from 'react-icons/all';
import styled from 'styled-components';
import {IndexImage} from 'components/landings/IndexImage';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import {StyledLi} from '../components/StyledSx';
import {ScreenSizes} from '../../types/responsive';

const IndexContent = styled.div`
  padding-top: 100px;
  padding-bottom: 90px;
  max-width: 515px;
`;

const PlanHeader = styled.div`
  padding: 60px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
`;

export const PlansTable = styled.div`
  position: relative;
  padding: 0 20px 0 50%;

  @media (max-width: 1280px) {
    padding: 0;
  }

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

const Plan = styled.div`
  margin: -30px 10px 50px 10px;
  position: relative;
  z-index: 1;
  width: 250px;
  flex-shrink: 0;
  box-shadow: ${(props) => props.theme.boxShadow};
  background: #ffffff;
  border-radius: ${(props) => props.theme.borderRadius};

  @media (max-width: 1280px) {
    width: 100%;
    margin: 50px 0 0;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin: 20px 0 0;
  }
`;

const ListRow = styled(StyledLi)`
  padding: 10px 40px;
  align-items: center;
  min-height: 66px;
  display: flex; // Use flexbox for layout
  justify-content: space-between; // Distribute space between items
  span {
    flex: 1; // Allow the text to take up as much space as it needs
    overflow: hidden; // Hide any overflow
    text-overflow: ellipsis; // Add an ellipsis (...) if the text is too long
    white-space: nowrap; // Prevent the text from wrapping to the next line
  }
  :nth-child(even) {
    background: #f5f5f5;
  }
  img {
    margin: auto 0;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding: 10px 24px;
    display: flex;
    img {
      margin: 0 8px 0 0;
    }
  }
`;

const PricingContent = styled(Content)`
  padding-bottom: 100px;
`;

const PlanLabels = styled.ul`
  padding: 0;
  position: absolute;
  top: 150px;
  margin: 0;
  left: 0;
  list-style: none;
  width: 100%;

  li:after {
    display: block;
    content: '';
    width: 75%;
  }

  @media (max-width: 1280px) {
    display: none;
  }
`;

const PlanFeatures = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  ${ListRow} {
    justify-content: center;
  }
`;

export const PlansList = styled.div`
  display: flex;
  justify-content: space-around;
  @media (max-width: 1280px) {
    justify-content: initial;
    overflow-x: scroll;
  }
`;

const YesIcon = styled(HiCheck)`
  font-size: 32px;
  color: green;
`;

const NoIcon = styled(HiOutlineX)`
  font-size: 32px;
  color: red;
`;

const Page: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="adyen-alternative"
        title={t('Adyen Alternative')}
        description={t(
          'Looking for the best alternative to Adyen? Accept all major payment methods in a single platform with MONEI’s payment gateway. Join now ››'
        )}
      />
      <IndexBackground sx={{left: {xl: '50%'}}}>
        <Content>
          <IndexContent>
            <SectionHeader underline tagName="h1">
              <Trans>Adyen Alternative</Trans>
            </SectionHeader>
            <Trans parent="p">
              Popular payment gateway Adyen is not your only option for online payments. Choose an
              alternative to Adyen like MONEI that will grow with your business.
            </Trans>
            <Trans parent="p">
              Sell more and your variable fee will go down, thanks to MONEI’s evolutive pricing
              system. Benefit from the lowest payment gateway transaction fees on the market, plus
              lower fixed fees.
            </Trans>
            <SectionActions>
              <SignUpButton variant="light" style={{marginRight: 15}}>
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
            <IndexImage
              top="60px"
              width="700px"
              src={selling}
              alt="Adyen Alternative"
              title="Adyen Alternative"
              className="hide-on-mobile"
            />
          </IndexContent>
        </Content>
      </IndexBackground>
      <Background>
        <PricingContent>
          <Section centered>
            <div style={{maxWidth: 800}}>
              <Trans parent="p">
                Integrate MONEI with your online store to offer your customers{' '}
                <InternalPageLink slug="payment-methods">multiple payment methods</InternalPageLink>{' '}
                including <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>.
              </Trans>
              <Trans parent="p">
                MONEI’s solution for digital and e-commerce payment processing is focused on
                innovation, reliability, and user satisfaction. Our engineers presented our
                architecture at{' '}
                <AnchorLink
                  href="https://aws.amazon.com/es/blogs/startups/serverless-architecture-powers-moneis-fast-secure-and-scalable-digital-payment-solutions/"
                  target="_blank"
                  title="Winner of the AWS’ Startup Architecture Challenge">
                  Amazon’s AWS Startup Architecture of the Year Program (Iberia region)
                </AnchorLink>
                — and we won!
              </Trans>
              <Trans parent="p">
                There are several advantages — for all types of online merchants — to choosing MONEI
                over Adyen as your payment gateway alternative.
              </Trans>
              <List>
                <Trans parent="li">
                  Become a verified merchant, and enjoy our revolutionary 1-day payment settlement
                  system.
                </Trans>
                <Trans parent="li">
                  With lower translation fees, you’ll have more money to reinvest and grow your
                  business.
                </Trans>
                <Trans parent="li">
                  <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                    3D Secure Payments
                  </BlogLink>{' '}
                  ensure you and your customers are protected.
                </Trans>
                <Trans parent="li">
                  Plug-in and sell in minutes — use our API to quickly and easily integrate MONEI
                  with your e-commerce platform.
                </Trans>
                <Trans parent="li">
                  Also built for financial institutions and banks, check out our white label payment
                  gateway solution, customers will only see your branding, not ours.
                </Trans>
              </List>
              <div style={{textAlign: 'center', marginBottom: 50}}>
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </div>
            </div>
          </Section>
          {/* i18next-extract-mark-ns-stop adyen-alternative */}
          {/* i18next-extract-mark-ns-start alternatives */}
          <PricingSection>
            <PlansTable>
              <PlanLabels>
                <Trans parent={ListRow}>3D Secure transactions</Trans>
                <Trans parent={ListRow}>1-day payment settlement</Trans>
                <Trans parent={ListRow}>Mobile payments</Trans>
                <Trans parent={ListRow}>Hosted service</Trans>
                <Trans parent={ListRow}>Google Pay</Trans>
                <Trans parent={ListRow}>Apple Pay</Trans>
                <Trans parent={ListRow}>PayPal</Trans>
                <Trans parent={ListRow}>Recurring payments</Trans>
                <Trans parent={ListRow}>PCI DSS Level 1</Trans>
                <Trans parent={ListRow}>
                  Integration with popular e-commerce platforms (Shopify, Wix, WooCommerce...)
                </Trans>
                <Trans parent={ListRow}>
                  Multi-user capabilities with different access levels (admin or read-only)
                </Trans>
                <Trans parent={ListRow}>Pay By Link (send a payment link via email or SMS)</Trans>
                <Trans parent={ListRow}>
                  Customize the appearance of the payment screen (logo, brand colors...)
                </Trans>
                <Trans parent={ListRow}>
                  Customizable payment screen to offer a 100% integrated experience
                </Trans>
                <Trans parent={ListRow}>REST API connection for all kinds of integrations</Trans>
                <Trans parent={ListRow}>Payment methods tokenization</Trans>
                <Trans parent={ListRow}>GraphQL API for data consumption</Trans>
                <Trans parent={ListRow}>
                  SMS and email notifications to clients for receipts, orders, and invoices
                </Trans>
                <Trans parent={ListRow}>
                  Smart routing engine sends payments sent to multiple acquirers to approve more
                  payments
                </Trans>
                <Trans parent={ListRow}>Full Direct Debit SEPA integration</Trans>
                <Trans parent={ListRow}>
                  Full white label solution for financial institutions and banks
                </Trans>
                <Trans parent={ListRow}>Combined acquiring and payment gateway solution</Trans>
                <Trans parent={ListRow}>
                  Transaction processing with new local payment methods like Bizum
                </Trans>
                <Trans parent={ListRow}>Installment payments</Trans>
                <Trans parent={ListRow}>
                  Dynamic billing — as you sell more, your transaction fees decrease
                </Trans>
              </PlanLabels>
              <PlansContainer>
                <PlansList>
                  <Plan>
                    <PlanHeader>
                      <img
                        src="https://assets.monei.com/images/logo.svg"
                        alt="Adyen alternative"
                        width={180}
                      />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>3D Secure transactions</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>1-day payment settlement</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Mobile payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Hosted service</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Google Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Apple Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PayPal</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Recurring payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PCI DSS Level 1</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Integration with popular e-commerce platforms (Shopify, Wix,
                          WooCommerce...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Multi-user capabilities with different access levels (admin or read-only)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Pay By Link (send a payment link via email or SMS)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customize the appearance of the payment screen (logo, brand colors...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customizable payment screen to offer a 100% integrated experience
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          REST API connection for all kinds of integrations
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Payment methods tokenization</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>GraphQL API for data consumption</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          SMS and email notifications to clients for receipts, orders, and invoices
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Smart routing engine sends payments sent to multiple acquirers to approve
                          more payments
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Full Direct Debit SEPA integration</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Full white label solution for financial institutions and banks
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Combined acquiring and payment gateway solution
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Transaction processing with new local payment methods like Bizum
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Installment payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Dynamic billing — as you sell more, your transaction fees decrease
                        </Trans>
                        <YesIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                  <Plan>
                    <PlanHeader>
                      <img src={logo} alt="Adyen Alternative" width={200} />
                    </PlanHeader>
                    <PlanFeatures>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>3D Secure transactions</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>1-day payment settlement</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Mobile payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Hosted service</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Google Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Apple Pay</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PayPal</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Recurring payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>PCI DSS Level 1</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Integration with popular e-commerce platforms (Shopify, Wix,
                          WooCommerce...)
                        </Trans>
                        No WooCommerce
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Multi-user capabilities with different access levels (admin or read-only)
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Pay By Link (send a payment link via email or SMS)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customize the appearance of the payment screen (logo, brand colors...)
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Customizable payment screen to offer a 100% integrated experience
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          REST API connection for all kinds of integrations
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Payment methods tokenization</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>GraphQL API for data consumption</Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          SMS and email notifications to clients for receipts, orders, and invoices
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Smart routing engine sends payments sent to multiple acquirers to approve
                          more payments
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Full Direct Debit SEPA integration</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Full white label solution for financial institutions and banks
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Combined acquiring and payment gateway solution
                        </Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Transaction processing with new local payment methods like Bizum
                        </Trans>
                        <NoIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>Installment payments</Trans>
                        <YesIcon />
                      </ListRow>
                      <ListRow>
                        <Trans parent={MobilePlanLabel}>
                          Dynamic billing — as you sell more, your transaction fees decrease
                        </Trans>
                        <NoIcon />
                      </ListRow>
                    </PlanFeatures>
                  </Plan>
                </PlansList>
              </PlansContainer>
            </PlansTable>
          </PricingSection>
        </PricingContent>
      </Background>
    </>
  );
};

export default Page;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "adyen-alternative", "alternatives"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
